<template>
  <div id="app" class="wrapper">
    <div class="wrapper__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>


<style>
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}
@font-face {
    font-family: 'Rostelecom Basis';
    src: url('./assets/fonts/RostelecomBasis-Light.otf');
    src: local('Rostelecom Basis Light'), local('RostelecomBasis-Light'),
        url('./assets/fonts/RostelecomBasis-Light.woff2') format('woff2'),
        url('./assets/fonts/RostelecomBasis-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Rostelecom Basis';
    src: url('./assets/fonts/RostelecomBasis-Regular.otf');
    src: local('Rostelecom Basis Regular'), local('RostelecomBasis-Regular'),
        url('./assets/fonts/RostelecomBasis-Regular.woff2') format('woff2'),
        url('./assets/fonts/RostelecomBasis-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Rostelecom Basis';
    src: url('./assets/fonts/RostelecomBasis-Medium.otf');
    src: local('Rostelecom Basis Medium'), local('RostelecomBasis-Medium'),
        url('./assets/fonts/RostelecomBasis-Medium.woff2') format('woff2'),
        url('./assets/fonts/RostelecomBasis-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Rostelecom Basis';
    src: url('./assets/fonts/RostelecomBasis-Bold.otf');
    src: local('Rostelecom Basis Bold'), local('RostelecomBasis-Bold'),
        url('./assets/fonts/RostelecomBasis-Bold.woff2') format('woff2'),
        url('./assets/fonts/RostelecomBasis-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}


:root {
    --app-height: 100%;
}

body {
  font-family: 'Rostelecom Basis';
  background-color: #FFFFFF;
  color: #2D2D2B;
  transition: all 300ms;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}


/*body::-webkit-scrollbar {
  display: none;
}*/

.v-toast {
    z-index: 99999999 !important;
}


</style>
