<template>
  <div class="welcome">
    <div class="welcome__bg"></div>
    <div class="welcome__box">
      <template v-if="!started">
        <div class="welcome__loading" v-if="!loaded">
          <!-- <div class="welcome__day-night"></div> -->
          <div class="welcome__loading-logo">
            <img src="@/assets/img/logo.svg" alt="" />
          </div>
          <div class="welcome__loading-line">
            <span></span>
          </div>
          <div class="welcome__loading-text">Портал времени открывается ...</div>
        </div>
        <!-- <StartScreen v-else @start="$emit('start')" /> -->
        <div class="welcome__start" v-else>
          <!-- <div class="welcome__day-night"></div> -->
          <div class="welcome__loading-logo">
            <img src="@/assets/img/logo.svg" alt="" />
          </div>
          <a href="javascript:void(0)" class="welcome__button" @click="$emit('start')"
            >Начинаем</a
          >
        </div>
      </template>
    </div>
    <!-- <template v-if="!started">
      <Preloader v-if="!loaded" />
    </template> -->
  </div>
</template>

<script>
// import Preloader from "@/components/Preloader.vue";
// import StartScreen from "@/components/StartScreen.vue";
export default {
  name: "Welcome",
  components: {
    // Preloader,
    // StartScreen,
  },
  data() {
    return {
      loaded: false,
      started: false,
      totalImages: 67,
      loadedImages: 0,
      showInfo: false,
      showSound: false,
      showMarker: false,
      showMenu: false,
    };
  },
  methods: {
    go() {
      this.started = true;
      setTimeout(() => {
        this.showMenu = true;
      }, 500);
      setTimeout(() => {
        this.showMarker = true;
      }, 2000);
      setTimeout(() => {
        this.showSound = true;
      }, 3500);
      setTimeout(() => {
        this.showInfo = true;
      }, 5000);
    },
    loadImage(index) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = require(`@/assets/img/feed/webp/${index}.jpg.webp`);

        img.onload = () => {
          this.loadedImages++;
          resolve();
        };

        img.onerror = reject;
      });
    },
    loadCustomImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;

        img.onload = () => {
          resolve();
        };

        img.onerror = reject;
      });
    },
    loadAllImages() {
      const imagePromises = [];
      imagePromises.push(this.loadCustomImage(require("@/assets/img/start-bg-1.jpg")));
      imagePromises.push(this.loadCustomImage(require("@/assets/img/start-bg-2.jpg")));
      imagePromises.push(
        this.loadCustomImage(require("@/assets/img/start-screen-mob-bg.jpg"))
      );

      for (let i = 1; i <= this.totalImages; i++) {
        imagePromises.push(this.loadImage(i));
      }

      Promise.all(imagePromises)
        .then(() => {
          //пара секунд задержки, на случай если все картинки загружены, чтобы экран лоадера не мигал
          setTimeout(() => {
            this.loaded = true;
          }, 2000);
        })
        .catch((error) => {
          console.error("Error loading images:", error);
        });
    },
  },
  computed: {
    loadingPercentage() {
      return (this.loadedImages / this.totalImages) * 100;
    },
  },
  watch: {
    loadedImages() {
      // обновление стиля для анимации полосы загрузки
      const loadingElement = this.$el.querySelector(".welcome__loading-line span");
      if (loadingElement) {
        loadingElement.style.width = `${this.loadingPercentage}%`;
      }
    },
  },
  mounted() {
    this.loadAllImages();
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.welcome
    &__bg
        width: 100vw
        height: 100vh
        height: var(--app-height)
        background-color: rgba(25, 0, 53, 0.84)
        -webkit-backdrop-filter: blur(10px)
        backdrop-filter: blur(10px)
        position: absolute
        top: 0
        left: 0
    &__box
        width: vw(1380px)
        height: 91vh
        border: 1px solid #FF4F12
        border-radius: 2px
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        margin: auto
        @media screen and (max-width: 1000px)
            width: 94%
            height: 97%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
    &__ref
        position: fixed
        left: 0
        top: 0
        width: 100vw
        height: var(--app-height)
        z-index: 100000000000000000
        pointer-events: auto
    &__loading
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        padding-top: vw(62px)
        &-text
            margin-top: vw(8px)
            color: #FFF
            font-size: vw(12px)
            @media screen and (max-width: 1000px)
                font-size: vwm(12px)
                margin-top: vwm(8px)
                letter-spacing: 0.1px
        &-line
            width: vw(257px)
            height: vw(5px)
            border: 1px solid #FF4F12
            margin-top: vw(19px)
            border-radius: vw(2px)
            @media screen and (max-width: 1000px)
                width: vwm(258px)
                height: vwm(5px)
                border-radius: vwm(2px)
                margin-top: vwm(0px)
            span
                display: block
                width: 0%
                height: 100%
                background-color: rgba(255,79,18,1)
                background: linear-gradient(90deg, rgba(255,79,18,1) 0%, rgba(74,0,231,1) 100%)
                //animation: loading
                //animation-duration: 5s
                //animation-fill-mode: forwards
                //animation-timing-function: linear
        &-logo
            width: vw(366px)
            @media screen and (max-width: 1000px)
                width: vwm(258px)
                margin-bottom: vwm(19px)
            img
                width: 100%
    &__start
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        width: 100%
        height: 100%
        padding-top: vw(62px)
        @media screen and (max-width: 1000px)
            padding-top: 0
    &__intro
        width: 100%
        height: 100%
        @media screen and (max-width: 1000px)
            height: auto
            display: flex
            flex-direction: column
    &__day-night
        width: vw(50px)
        height: vw(40px)
        position: absolute
        left: 0
        right: 0
        background-image: url(../assets/img/day-night.svg)
        background-size: contain
        top: -1px
        left: -1px
        cursor: pointer
        background-repeat: no-repeat
        @media screen and (max-width: 1000px)
            width: vwm(50px)
            height: vwm(40px)
    &__button
        color: #fff
        padding: vw(15px) vw(50px)
        border: 1px solid #FF4F12
        background: #FF4F12
        -webkit-backdrop-filter: blur(2.5px)
        backdrop-filter: blur(2.5px)
        text-transform: uppercase
        font-size: vw(16px)
        letter-spacing: vw(0.8px)
        font-weight: 500
        margin-top: vw(-2px)
        transition: all 300ms
        font-family: 'Tektur'
        border-radius: 2px
        @media screen and (max-width: 1000px)
            padding: vwm(14px) vwm(48px)
            font-size: vwm(16px)
            border-radius: 2px
            letter-spacing: 0.9px
        &--3
            position: static !important
        &--2
            width: vw(254px)
            padding: 0
            height: vw(50px)
            display: block
            margin: auto
            margin-top: vw(20px)
            line-height: vw(50px)
            text-align: center
            @media screen and (max-width: 1000px)
                width: auto
                height: auto
                position: absolute
                left: 0
                right: 0
                width: vwm(240px)
                margin-top: 0
                bottom: vwm(70px)
                padding: vwm(18px) vwm(20px)
        // &:hover
        // 	background: radial-gradient(44.91% 103.92% at 50% 50%, rgba(255, 79, 18, 0.03) 0%, rgba(255, 79, 18, 0.30) 100%)
        // &:active
        // 	background: rgba(255, 79, 18, 0.50)
        &[disabled]
            opacity: 0.4
            cursor: default
            // &:hover
            // 	background: rgba(255, 79, 18, 0.10)
            // &:active
            // 	background: rgba(255, 79, 18, 0.10)
        &.desk-only
            @media screen and (max-width: 1000px)
                display: none
        &.mob-only
            display: none
            @media screen and (max-width: 1000px)
                display: block
    &__menu
        width: vw(50px)
        height: vw(40px)
        position: absolute
        background: #FF4F12
        top: -1px
        right: -1px
        cursor: pointer
        border: 1px solid rgba(255, 79, 18, 0.80)
        border-radius: 0 2px 0 2px
        transition: all 300ms
        z-index: 10
        @media screen and (max-width: 1000px)
            width: vwm(50px)
            height: vwm(40px)
        &:after
            content: ''
            width: vw(32px)
            height: vw(32px)
            background-size: contain
            position: absolute
            background-repeat: no-repeat
            top: 0
            bottom: 0
            right: 0
            left: 0
            margin: auto
            background-image: url(../assets/img/menu-bg-2.svg)
            transition: all 300ms
            @media screen and (max-width: 1000px)
                width: vwm(32px)
                height: vwm(32px)
        &:hover
            background: rgba(255, 79, 18, 0.8)
            &:after
                background-image: url(../assets/img/menu-bg-hover-2.svg)
        &:active
            background: #FF4F12
            &:after
                background-image: url(../assets/img/menu-bg-click-2.svg)
    &__line
        width: vw(1330px)
        left: -1px
        height: vw(40.04px)
        border: 1px solid rgba(255, 79, 18, 0.80)
        background-color: rgba(255, 79, 18, 0.10)
        webkit-backdrop-filter: blur(10px)
        backdrop-filter: blur(10px)
        bottom: -1px
        position: absolute
        border-right: none
        @media screen and (max-width: 1000px)
            height: vwm(40px)
            width: vwm(304px)
            bottom: -1px
            backdrop-filter: none
        span
            display: block
            width: vw(645px)
            height: 100%
            background: linear-gradient(270deg, #4A00E7 0%, #FF4F12 100%)
            @media screen and (max-width: 1000px)
                width: vwm(176px)
        .hanlder
            width: vw(91px)
            height: vw(47px)
            background-image: url(../assets/img/handler.svg)
            background-size: contain
            background-repeat: no-repeat
            color: #70F
            text-align: center
            font-family: 'Tektur'
            font-weight: 600
            font-size: vw(23px)
            top: vw(-36px)
            padding-top: vw(3px)
            left: vw(600px)
            position: absolute
            @media screen and (max-width: 1000px)
                width: vwm(89px)
                height: vwm(47px)
                font-size: vwm(23px)
                top: vwm(-33px)
                left: vwm(132px)
                padding-top: vwm(3px)
        .move
            width: vw(44px)
            height: vw(20px)
            background-image: url(../assets/img/move.svg)
            position: absolute
            background-size: contain
            left: vw(820px)
            top: vw(19px)
            background-repeat: no-repeat
            @media screen and (max-width: 1000px)
                display: none
    &__sound
        width: vw(50px)
        height: vw(40px)
        position: absolute
        background: #FF4F12
        background-size: contain
        bottom: -1px
        right: -1px
        cursor: pointer
        border: 1px solid rgba(255, 79, 18, 0.80)
        border-radius: 0 0 2px 0
        transition: all 300ms
        z-index: 10
        @media screen and (max-width: 1000px)
            width: vwm(50px)
            height: vwm(40px)
        &:after
            content: ''
            width: vw(32px)
            height: vw(32px)
            background-size: contain
            position: absolute
            background-repeat: no-repeat
            top: 0
            bottom: 0
            right: 0
            left: 0
            margin: auto
            background-image: url(../assets/img/sound-bg-2.svg)
            transition: all 300ms
            @media screen and (max-width: 1000px)
                width: vwm(32px)
                height: vwm(32px)
        &:hover
            background: rgba(255, 79, 18, 0.8)
            &:after
                background-image: url(../assets/img/sound-bg-hover-2.svg)
        &:active
            background: #FF4F12
            &:after
                background-image: url(../assets/img/sound-bg-click-2.svg)
    &__play
        width: vw(50px)
        height: vw(40px)
        position: absolute
        background: #FF4F12
        background-size: contain
        bottom: -1px
        left: -1px
        cursor: pointer
        border: 1px solid rgba(255, 79, 18, 0.80)
        border-radius: 0 0 2px 0
        transition: all 300ms
        z-index: 10
        @media screen and (max-width: 1000px)
            width: vwm(50px)
            height: vwm(40px)
        &:after
            content: ''
            width: vw(32px)
            height: vw(32px)
            background-size: contain
            position: absolute
            background-repeat: no-repeat
            top: 0
            bottom: 0
            right: 0
            left: 0
            margin: auto
            background-image: url(../assets/img/play-bg-2.svg)
            transition: all 300ms
            @media screen and (max-width: 1000px)
                width: vwm(32px)
                height: vwm(32px)
        &:hover
            background: rgba(255, 79, 18, 0.8)
            // &:after
            // 	background-image: url(../assets/img/play-bg-hover.svg)
        &:active
            background: #FF4F12
            // &:after
            // 	background-image: url(../assets/img/play-bg-click.svg)
    &__info
        width: vw(50px)
        height: vw(40px)
        position: absolute
        background: #FF4F12
        background-size: contain
        top: -1px
        left: -1px
        cursor: pointer
        border: 1px solid rgba(255, 79, 18, 0.80)
        border-radius: 0 0 2px 0
        transition: all 300ms
        z-index: 10
        @media screen and (max-width: 1000px)
            width: vwm(50px)
            height: vwm(40px)
        &:after
            content: ''
            width: vw(12px)
            height: vw(20px)
            background-size: contain
            position: absolute
            background-repeat: no-repeat
            top: 0
            bottom: 0
            right: 0
            left: 0
            margin: auto
            background-image: url(../assets/img/info-bg.svg)
            transition: all 300ms
            @media screen and (max-width: 1000px)
                width: vwm(12px)
                height: vwm(20px)
        &:hover
            &:after
                background-image: url(../assets/img/info-bg-hover.svg)
        &:active
            &:after
                background-image: url(../assets/img/info-bg-click.svg)
    &__close-info
        width: vw(50px)
        height: vw(40px)
        position: absolute
        background: #FF4F12
        background-size: contain
        top: -1px
        left: -1px
        cursor: pointer
        border: 1px solid rgba(255, 79, 18, 0.80)
        border-radius: 0 0 2px 0
        transition: all 300ms
        z-index: 10
        @media screen and (max-width: 1000px)
            width: vwm(50px)
            height: vwm(40px)
        &:after
            content: ''
            width: vw(32px)
            height: vw(32px)
            background-size: contain
            position: absolute
            background-repeat: no-repeat
            top: 0
            bottom: 0
            right: 0
            left: 0
            margin: auto
            background-image: url(../assets/img/close-border-2.svg)
            transition: all 300ms
            @media screen and (max-width: 1000px)
                width: vwm(32px)
                height: vwm(32px)
        &:hover
            background: rgba(255, 79, 18, 0.8)
            // &:after
            // 	background-image: url(../assets/img/close-border-hover.png)
        &:active
            background: #FF4F12
            &:after
                background-image: url(../assets/img/close-border-click-2.svg)
    &__text
        width: vw(528px)
        font-size: vw(16px)
        color: #fff
        text-align: center
        margin: auto
        margin-top: vw(9px)
        line-height: 1.3
        @media screen and (max-width: 1000px)
            font-size: vwm(15px)
            line-height: normal
            width: vwm(270px)
            margin-top: 0
            margin-bottom: vwm(15px)
            display: none
            br
                display: none
    &__title
        font-size: vw(42px)
        font-weight: 500
        font-family: 'Tektur'
        color: #fff
        text-align: center
        padding-top: vw(213px)
        @media screen and (max-width: 1000px)
            font-size: vwm(38px)
            line-height: vwm(40px)
            padding-top: 0
            margin-bottom: vwm(13px)
            display: none
    &__hint
        color: #FFB49A
        font-size: vw(14px)
        position: absolute
        display: flex
        flex-direction: column
        @media screen and (max-width: 1000px)
            font-size: vwm(14px)
            line-height: normal
            br
                display: none
        br
            &.mob-only
                display: none
                @media screen and (max-width: 1000px)
                    display: block
        em
            font-size: vw(25px)
            font-family: 'Tektur'
            color: #fff
            font-weight: 500
            margin-bottom: vw(8px)
            position: relative
            display: inline-block
            &:after
                content: ''
                width: vw(20px)
                height: vw(19px)
                background-size: contain
                background-repeat: no-repeat
                background-image: url(../assets/img/hint-arrow.svg)
                display: block
                position: absolute
                top: 0
                bottom: 0
                margin: auto
                @media screen and (max-width: 1000px)
                    width: vwm(20px)
                    height: vwm(19px)
            @media screen and (max-width: 1000px)
                font-size: vwm(20px)
                margin-bottom: 0
        p
            @media screen and (max-width: 1000px)
                display: none
        span
            position: absolute
            width: vw(65px)
            height: vw(46px)
            background-image: url(../assets/img/arrow-1.svg)
            display: block
            background-size: contain
            background-repeat: no-repeat
            left: vw(-79px)
            top: vw(-51px)
            display: none !important
            @media screen and (max-width: 1000px)
                background-image: url(../assets/img/arrow-3.svg)
                width: vwm(8px)
                height: vwm(27px)
                left: vwm(-3px)
                top: vwm(-42px)
        &--2
            left: vw(1119px)
            top: vw(75px)
            @media screen and (max-width: 1000px)
                left: auto
                right: vwm(46px)
                top: vwm(55px)
            em
                margin-left: auto
                &:after
                    transform: rotate(180deg)
                    right: vw(-30px)
                    @media screen and (max-width: 1000px)
                        right: vwm(-30px)
                        top: vwm(4px)
            p
                text-align: right
            span
                top: vw(39px)
                left: vw(189px)
                background-image: url(../assets/img/arrow-01.svg)
                transform: none
                @media screen and (max-width: 1000px)
                    background-image: url(../assets/img/arrow-04.svg)
                    width: vwm(92px)
                    height: vwm(46px)
                    transform: scale(1, 1)
                    left: vwm(191px)
                    top: vwm(34px)
        &--3
            left: vw(472px)
            top: vw(261px)
            @media screen and (max-width: 1000px)
                top: vwm(221px)
                left: vwm(156px)
            em
                &:after
                    transform: rotate(90deg)
                    left: vw(-26px)
                    top: vw(7px)
                    bottom: auto
                    @media screen and (max-width: 1000px)
                        left: vwm(-25px)
                        top: vwm(7px)
            &.desk-only
                @media screen and (max-width: 1000px)
                    display: none
            &.mob-only
                display: none
                @media screen and (max-width: 1000px)
                    display: block
            &:after
                content: ''
                display: block
                width: vw(83px)
                height: vw(83px)
                background-size: contain
                background-repeat: no-repeat
                left: vw(-118px)
                top: vw(-18px)
                position: absolute
                background-image: url(../assets/img/intro-2.svg)
                @media screen and (max-width: 1000px)
                    width: vwm(83px)
                    height: vwm(83px)
                    left: vwm(-117px)
                    top: vwm(-18px)
            span
                background-image: url(../assets/img/arrow-03.svg)
                width: vw(57px)
                height: vw(51px)
                left: vw(160px)
                top: vw(113px)
                @media screen and (max-width: 1000px)
                    background-image: url(../assets/img/arrow-3.svg)
                    width: vwm(9px)
                    height: vwm(27px)
                    transform: rotate(-90deg)
                    left: vwm(-34px)
                    top: vwm(-2px)
        &--4
            left: vw(708px)
            bottom: vw(70px)
            @media screen and (max-width: 1000px)
                left: 0
                right: 0
                margin: auto
                text-align: center
                bottom: vwm(131px)
            em
                &:after
                    left: vw(-31px)
                    @media screen and (max-width: 1000px)
                        left: 0
                        right: 0
                        margin: auto
                        top: auto
                        bottom: vwm(-14px)
            span
                background-image: url(../assets/img/arrow-2.svg)
                width: vw(77px)
                height: vw(51px)
                left: vw(174px)
                top: vw(44px)
                @media screen and (max-width: 1000px)
                    background-image: url(../assets/img/arrow-5.svg)
                    width: vwm(54px)
                    height: vwm(25px)
                    left: auto
                    right: vwm(-1px)
                    top: auto
                    bottom: vwm(-15px)
        &--5
            left: vw(1099px)
            bottom: vw(70px)
            @media screen and (max-width: 1000px)
                left: auto
                right: vwm(15px)
                bottom: vwm(64px)
            em
                margin-left: auto
                &:after
                    right: vw(-30px)
                    @media screen and (max-width: 1000px)
                        right: vwm(0px)
                        top: auto
                        bottom: vwm(-17px)
            p
                text-align: right
            span
                background-image: url(../assets/img/arrow-03.svg)
                width: vw(54px)
                height: vw(48px)
                left: vw(190px)
                top: vw(82px)
                @media screen and (max-width: 1000px)
                    background-image: url(../assets/img/arrow-05.svg)
                    width: vwm(153px)
                    height: vwm(133px)
                    top: vwm(93px)
                    left: vwm(100px)
        &--6
            left: vw(55px)
            bottom: vw(70px)
            @media screen and (max-width: 1000px)
                left: vwm(15px)
                bottom: vwm(64px)
            em
                &:after
                    left: vw(-30px)
                    @media screen and (max-width: 1000px)
                        left: vwm(0px)
                        top: auto
                        bottom: vwm(-17px)

@keyframes loading
    0%
        width: 0%
    100%
        width: 100%
.mob-only
    display: none
    @media screen and (max-width: 1000px)
        display: inline
.desk-only
    @media screen and (max-width: 1000px)
        display: none
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
